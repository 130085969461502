import { isValid, parse, isAfter, format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Button } from "ui-2";
import { usePromise } from "utils";
import { updateMilestone } from "../../../../api-calls/updateMilestone";
import DateInput from "../../../../components/DateInput";
import { useToastConfig } from "../../../../components/toast";
import GTM_ACTIONS from "../../../../config/gtmActions";
import { LIFESTAGE } from "../../../../constants/lifestage";
import { datalayerPush } from "../../../../lib/gtm";
import { useMilestones } from "./context";


export function CompleteMilestoneForm({
  petId,
  milestoneId,
}: Readonly<{
  petId: string;
  milestoneId: string;
}>) {
  const { t } = useTranslation();
  const { showToast } = useToastConfig();
  const [{ isPending, isSuccess, isError }, { exec: saveMilestone }] =
    usePromise(updateMilestone);

  const { refetchMilestones } = useMilestones();

  const [ dateError, setDateError] = useState<string | undefined>(undefined);
  const [completedDate] = useState<string>(format(new Date(), "yyyy-MM-dd")); 

  useEffect(() => {
    if (isSuccess) {
      showToast({
        description: t(LIFESTAGE.UPDATE_SUCCESS),
        type: "success",
      });
      datalayerPush(GTM_ACTIONS.ADD_A_MILESTONE_DATE);
      refetchMilestones();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast({
        description: t(LIFESTAGE.UPDATE_FAILED),
        type: "error",
      });
    }
  }, [isError]);

  useEffect(() => { 
    if (dateError) {
      showToast({
        description: dateError,
        type: "error",
      });
    }
  }, [dateError]);

  const handleSubmit = useCallback(({ completedDate }: { completedDate?: string }) => { 

    const enteredDate = parse(completedDate || "", "yyyy-MM-dd", new Date());
    if (isValid(enteredDate) && isAfter(enteredDate, new Date())) {
      setDateError(t(LIFESTAGE.VALID_DATE) as string);
    } else {
      setDateError(undefined);
      saveMilestone({
        petId,
        petMilestoneId: milestoneId,
        completedDate: completedDate || "",
      });
    }
  }, [saveMilestone, petId, milestoneId, t]);

  

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ completedDate: format(new Date(), "yyyy-MM-dd") }}
      render={({ handleSubmit, form }) => {
        useEffect(() => {
          form.change("completedDate", format(new Date(), "yyyy-MM-dd")); 
        }, []);
        const isValidDate = isValid(
          parse(form.getState().values.completedDate || "", "yyyy-MM-dd", new Date())
        );

        return (
          <form onSubmit={handleSubmit}>
            <label className="label2 md:label1">
              <span className="mb-2">{t(LIFESTAGE.DATE_COMPLETED)}</span>
              <Field name="completedDate" value={completedDate}>
                {({ input }) => (
                  <DateInput {...input} showCalendar max={new Date()} />
                )}
              </Field>
            </label>
            <div className="absolute bottom-0 left-0 flex h-20 w-full items-center justify-end bg-white px-5 md:border-t-[1px]">
              <Button
                classes="lg:label1 max-md:w-full"
                disabled={isPending || !isValidDate}
              >
                {t(LIFESTAGE.MARK_AS_COMPLETE)}
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
}