import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";
import { Button, FormControl } from "ui-2";
import SideModal from "../../components/Modal/SideModal";
import BrandSelection from "./BrandSelection/BrandSelection";
import { IFOOD_BRANDS } from "../../constants/foodSelectOtherBrands";
import { FOOD_TYPES } from "../../constants/foodTypes";
import FoodSelectionModal from "./FoodSelectionModal/FoodSelectionModal";
import { setFoodSelect } from "../../api-calls/setFoodSelect";
import { useToastConfig } from "../../../src/components/toast";
import GTM_ACTIONS from "../../config/gtmActions";
import { datalayerPush } from "../../lib/gtm";
import { useProducts } from "@pages/MyPets/context/ProductsContext";
import { Product } from "@api-calls/fetchProducts";
import { classnames } from "utils";

interface FoodSelectData {
  petFoodTypeId: string;
  petFoodTypeInfo?: string;
  petFoodTypeDetails?: IFOOD_BRANDS;
}

const FoodTypeModalContent = ({
  petName,
  petId,
  petType,
  onFoodSelectionSaved,
  onClose,
  products,
}: {
  petName: string | undefined;
  petId: string | undefined;
  petType: "dog" | "cat";
  onFoodSelectionSaved: () => void;
  onClose: () => void;
  products: Product[];
}) => {
  const region = window?.hph_locale;
  const { t } = useTranslation();
  const [isFoodSelectionOpen, setIsFoodSelectionOpen] = useState(false);
  // New state for modal width
  const [isWideModal, setIsWideModal] = useState(false);
  // New state for narrower margins for food select modal
  const [useFoodSelectMargin, setUseFoodSelectMargin] = useState(false);

  const { showToast } = useToastConfig();

  useEffect(() => {
    if (!isFoodSelectionOpen) {
      setIsWideModal(false);
      setUseFoodSelectMargin(false);
    }
  }, [isFoodSelectionOpen]);

  const handleFormSubmit = async (values: FoodSelectData) => {
    try {
      let petFoodTypeInfo = "";

      if (values.petFoodTypeId === FOOD_TYPES.HILLS_PRODUCT) {
        datalayerPush(GTM_ACTIONS.MY_PETS_DIET_SELECT_HILLS_PRODUCT);
        setIsWideModal(true);
        setIsFoodSelectionOpen(true);
        setUseFoodSelectMargin(true);
      } else if (values.petFoodTypeId === FOOD_TYPES.OTHER_BRAND) {
        if (values.petFoodTypeDetails && values.petFoodTypeDetails.brandName) {
          petFoodTypeInfo = values.petFoodTypeDetails.brandName;
          datalayerPush({
            ...GTM_ACTIONS.MY_PETS_DIET_SELECT_OTHER_BRAND,
            click_text: `my pets diet click select other brand: ${petFoodTypeInfo}`, // Push GA event for other brand selection with brand name
          });
        } else {
          throw new Error(
            t("myPets.foodTypeModal.petFoodOtherBrandError") || "Error"
          );
        }
      } else if (values.petFoodTypeId === FOOD_TYPES.HOMEMADE) {
        petFoodTypeInfo = t("myPets.foodTypeModal.Homemade");
        datalayerPush({
          ...GTM_ACTIONS.MY_PETS_DIET_SELECT_OTHER_BRAND,
          click_text: `my pets diet click select homemade: ${petFoodTypeInfo}`, // Push GA event for other brand selection with brand name
        });
      }

      if (
        values.petFoodTypeId === FOOD_TYPES.OTHER_BRAND ||
        values.petFoodTypeId === FOOD_TYPES.HOMEMADE
      ) {
        datalayerPush({
          ...GTM_ACTIONS.MY_PETS_DIET_SELECT_SAVE,
          click_text: `my pets diet click save: ${petFoodTypeInfo}`,
        });

        if (petId) {
          const response = await setFoodSelect(
            petId,
            Number(values.petFoodTypeId),
            petFoodTypeInfo
          );

          if (response) {
            showToast({
              title: "Success",
              type: "success",
              description: t("myPets.diet.foodTypeSave"),
            });
            onFoodSelectionSaved();
            onClose();
          }
        }
      }
    } catch (error) {
      let errorMessage;
      if (
        (error as Error).message ===
        t("myPets.foodTypeModal.petFoodOtherBrandError")
      ) {
        errorMessage = t("myPets.foodTypeModal.petFoodOtherBrandError");
      } else {
        errorMessage = t("myPets.foodTypeModal.petFoodCommonError");
      }
      showToast({
        title: "Error",
        type: "error",
        description: errorMessage,
      });
    }
  };

  const handleClose = () => {
    setIsFoodSelectionOpen(false);
    setIsWideModal(false);
    setUseFoodSelectMargin(false);
    onClose();
  };

  const handleFoodSelectionModalClose = async (id?: string) => {
    if (id && petId !== undefined) {
      const selectedProduct = products.find((product) => product.id === id);
      if (selectedProduct) {
        // Save data and trigger the callback to re-render the Diet tab
        try {
          const petFoodTypeId = Number(FOOD_TYPES.HILLS_PRODUCT);
          const petFoodTypeInfo = id;
          const response = await setFoodSelect(
            petId,
            petFoodTypeId,
            petFoodTypeInfo
          );

          if (response) {
            showToast({
              title: "Success",
              type: "success",
              description: t("myPets.diet.foodTypeSave"),
            });
            setIsFoodSelectionOpen(false);
            onFoodSelectionSaved();
            onClose();
          }
        } catch (error) {
          console.error("Error saving selected food:", error);
          showToast({
            title: "Error",
            type: "error",
            description: t("myPets.foodSelectionModal.foodSavingError"),
          });
        }
      }
    }
  };

  return (
    <SideModal
      title={t("myPets.foodTypeModal.selectFood", { petName })}
      data-testid="modal-component"
      onClose={handleClose}
      contentClasses={`${isWideModal ? "lg:!w-4/5" : null}`}
      headerClasses={"!justify-start pr-5 pl-6 lg:pl-14 !justify-center"}
    >
      <div
        className={classnames(
          useFoodSelectMargin
            ? "h-fit lg:mx-[40px] lg:mt-8"
            : "mx-5 mt-8 mb-10 h-fit lg:mx-[155px]"
        )}
      >
        {!isFoodSelectionOpen ? (
          <>
            <span
              className="label1 justify-start lg:justify-center"
              data-testid="food-type-title"
            >
              {t("myPets.foodTypeModal.petsFood")}
            </span>
            <div className="mt-2">
              <Form
                onSubmit={handleFormSubmit}
                render={({ handleSubmit, values, form }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="flex flex-col gap-4"
                    data-testid="food-type-form"
                  >
                    <div
                      onClick={() =>
                        form.change("petFoodTypeId", FOOD_TYPES.HILLS_PRODUCT)
                      }
                      className="cursor-pointer"
                      data-testid="food-hills-product-wrapper"
                    >
                      <FormControl
                        fieldProps={{
                          name: "petFoodTypeId",
                          component: "input",
                          value: FOOD_TYPES.HILLS_PRODUCT,
                        }}
                        type="radio"
                        label={t("myPets.foodTypeModal.Hill's Product")}
                        variant="block"
                        isUncheckable={true}
                        data-testid="food-hills-product"
                      />
                    </div>
                    <div
                      onClick={() =>
                        form.change("petFoodTypeId", FOOD_TYPES.OTHER_BRAND)
                      }
                      className="cursor-pointer"
                      data-testid="food-other-brand-wrapper"
                    >
                      <FormControl
                        fieldProps={{
                          name: "petFoodTypeId",
                          component: "input",
                          value: FOOD_TYPES.OTHER_BRAND,
                        }}
                        type="radio"
                        label={t("myPets.foodTypeModal.Other Brand")}
                        variant="block"
                        isUncheckable={true}
                        data-testid="food-other-brand"
                      >
                        {values?.petFoodTypeId?.toString() ===
                          FOOD_TYPES.OTHER_BRAND && (
                          <span
                            className="mt-4 w-full"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                            data-testid="brand-selection"
                          >
                            <BrandSelection
                              setFoodTypeDetails={(value) =>
                                form.mutators.setFoodTypeDetails(value)
                              }
                              selectedRegion={region}
                            />
                          </span>
                        )}
                      </FormControl>
                    </div>
                    <div
                      onClick={() =>
                        form.change("petFoodTypeId", FOOD_TYPES.HOMEMADE)
                      }
                      className="cursor-pointer"
                      data-testid="homemade-food-wrapper"
                    >
                      <FormControl
                        fieldProps={{
                          name: "petFoodTypeId",
                          component: "input",
                          value: FOOD_TYPES.HOMEMADE,
                        }}
                        type="radio"
                        label={t("myPets.foodTypeModal.Homemade")}
                        variant="block"
                        isUncheckable={true}
                        data-testid="homemade-food"
                      />
                    </div>
                    <div className="fixed bottom-0 right-0 flex h-20 w-full items-center justify-end border-t-[1px] bg-white px-5 lg:w-3/5">
                      <Button
                        type="submit"
                        classes="lg:label1 w-full md:w-[207px]"
                        data-testid="save-button"
                      >
                        {values.petFoodTypeId === FOOD_TYPES.HILLS_PRODUCT
                          ? t("myPets.foodTypeModal.next")
                          : t("myPets.foodTypeModal.save")}
                      </Button>
                    </div>
                  </form>
                )}
              />
            </div>
          </>
        ) : (
          <FoodSelectionModal
            petType={petType}
            updateSelectedFood={handleFoodSelectionModalClose}
            resetFiltersTrigger={false}
          />
        )}
      </div>
    </SideModal>
  );
};

const FoodTypeModal = ({
  petName,
  petId,
  petType,
  onFoodSelectionSaved,
  onClose,
}: {
  petName: string | undefined;
  petId: string | undefined;
  petType: "dog" | "cat";
  onFoodSelectionSaved: () => void;
  onClose: () => void;
}) => {
  const products = useProducts({ pet: petType });

  if (!products.isSuccess) {
    return null;
  } else {
    return (
      <FoodTypeModalContent
        petName={petName}
        petId={petId}
        petType={petType}
        onFoodSelectionSaved={onFoodSelectionSaved}
        onClose={onClose}
        products={products.data}
      />
    );
  }
};

export default FoodTypeModal;
