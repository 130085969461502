import { useState } from "react";
import { datalayerPush } from "@lib/gtm";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "ui-2";
import PetLoader from "../../components/PetLoader";
import GTM_ACTIONS from "../../config/gtmActions";
import {
  ACCOUNT_SETTINGS,
  AUTHENTICATION_STATUS,
  ROUTES_PATH,
} from "../../constants";
import useRequireAuth from "../../hooks/useRequireAuth";
import { GearIcon } from "../../icons/GearIcon";
import { PencilIcon } from "../../icons/PencilIcon";
import { ProfilePicture } from "./components/ProfilePicture";
import PassKeyPopUp from "../../components/PassKeyPopUp";
import { useFeatureFlagsConfig } from "../../hooks/useFeatureFlags";
import gigya from "../../lib/gigya";

export default function AccountSettings() {
  const { t } = useTranslation();
  const { status, user, logout, fetchMyProfileData } = useRequireAuth();
  const navigate = useNavigate();
  const { showPassKey } = useFeatureFlagsConfig();
  const [showPassKeyPopUp, setShowPassKeyPopUp] = useState(true);
  if (status !== AUTHENTICATION_STATUS.AUTHENTICATED || !user) {
    return <PetLoader />;
  }
  const accountLogout = () => {
    datalayerPush(GTM_ACTIONS.ACCOUNT_SETTINGS_LOGOUT);
    logout?.();
  };

  const showPassKeyNotification =
    showPassKey &&
    showPassKeyPopUp &&
    !(user.data?.DNDPasskey || user.data?.isPasskeyAdded);
  const setDNDPasskey = (value: boolean) => {
    gigya.accounts.setAccountInfo({
      data: {
        DNDPasskey: value,
      },
    });
    fetchMyProfileData?.();
  };

  return (
    <div>
      <div className="flex flex-col gap-8 px-5 py-6 md:p-0">
        <div className="hidden lg:flex items-center gap-2 bg-brand-color-library-blue-800 py-4 px-5 md:mx-0 md:bg-transparent md:p-0">
          <div className="flex flex-row">
            <GearIcon className="h-6 w-6 fill-brand-color-library-gray-000 md:h-8 md:w-8 md:fill-brand-color-library-gray-800" />
          </div>
          <h1
            data-testid="account-settings-heading"
            className="headline5 md:headline3 text-brand-color-library-gray-000 md:text-brand-color-library-gray-800"
          >
            {t(ACCOUNT_SETTINGS.HEADING)}
          </h1>
        </div>
        {showPassKeyNotification && (
          <PassKeyPopUp
            setDNDPasskey={setDNDPasskey}
            setShowPassKeyPopUp={setShowPassKeyPopUp}
          />
        )}
        <div className="md:mt-0">
          <h2
            data-testid="account-settings-profile-picture"
            className="label2 md:label1 mb-2 text-brand-color-library-gray-900"
          >
            {t(ACCOUNT_SETTINGS.PROFILE_PICTURE)}
          </h2>
          <ProfilePicture />
        </div>
        <div>
          <div className="flex flex-col gap-4 text-brand-color-library-gray-900">
            <div className="flex items-center justify-between">
              <h2 className="label2 md:label1 text-brand-color-library-gray-900">
                {t(ACCOUNT_SETTINGS.BASIC_INFORMATION)}
              </h2>
              <Button
                prefixIcon={<PencilIcon />}
                variant="blueText"
                onClick={() => navigate(ROUTES_PATH.EDIT_BASIC_INFO)}
              >
                {t(ACCOUNT_SETTINGS.EDIT)}
              </Button>
            </div>
            <div className="overflow-hidden text-ellipsis">
              <span className="body3 md:body2 mr-2 text-brand-color-library-gray-500">
                {t(ACCOUNT_SETTINGS.NAME)}
              </span>
              <span className="body2 capitalize text-brand-color-library-gray-900">
                {user.profile.firstName} {user.profile.lastName}
              </span>
            </div>
            <div className="body3 md:body2 overflow-hidden text-ellipsis">
              <span className="mr-2 text-brand-color-library-gray-500">
                {t(ACCOUNT_SETTINGS.EMAIL)}
              </span>
              <span className="body2 text-brand-color-library-gray-900">
                {user.profile.email}
              </span>
            </div>
            <Link
              className="link3 md:link2 cursor-pointer text-brand-color-library-blue-500 underline"
              to={ROUTES_PATH.COMMUNICATION_PREFERENCES}
            >
              {t(ACCOUNT_SETTINGS.CHANGE_COMMUNICATION_PREFERENCES)}
            </Link>
          </div>
        </div>
        <div>
          <div className="mb-4 flex items-center justify-between">
            <h2 className="label2 md:label1 text-brand-color-library-gray-900">
              {t(ACCOUNT_SETTINGS.SECURITY)}
            </h2>
            <Button
              prefixIcon={<PencilIcon />}
              variant="blueText"
              onClick={() => navigate(ROUTES_PATH.CHANGE_PASSWORD)}
            >
              {t(ACCOUNT_SETTINGS.EDIT)}
            </Button>
          </div>
          <div>
            <span className="mr-2 text-brand-color-library-gray-500">
              {t(ACCOUNT_SETTINGS.PASSWORD)}
            </span>
            <span className="text-brand-color-library-gray-900">
              **********
            </span>
          </div>
          <div className="mt-4">
            {showPassKey && (
              <Link
                className="link3 md:link2 cursor-pointer text-brand-color-library-blue-500 underline"
                to={ROUTES_PATH.MANAGE_PASSKEY}
              >
                {t(ACCOUNT_SETTINGS.MANAGE_PASSKEY)}
              </Link>
            )}
          </div>
        </div>
        <div>
          <Button
            classes="block m-auto mb-6"
            variant="blueText"
            onClick={accountLogout}
          >
            {t(ACCOUNT_SETTINGS.LOG_OUT)}
          </Button>
        </div>
      </div>
    </div>
  );
}
