import { xCloseBtn } from "../../icons/xCloseBtn";
import { Form } from "react-final-form";
import { Button, FormControl } from "ui-2";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES_PATH, ACCOUNT_SETTINGS } from "../../constants";

const PassKeyPopUp = ({setDNDPasskey, setShowPassKeyPopUp} : {
  setDNDPasskey: (value: boolean) => void;
  setShowPassKeyPopUp: (value: boolean) => void;
} ) => {

  const [isChecked, setIsChecked] = useState(false);
  const onCheckboxClick = (isChecked: boolean) => {
    setIsChecked(isChecked);
  }

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex max-w-2xl flex-row items-start justify-start rounded-lg bg-[#EEF5FF] p-4 lg:p-6 justify-between">
      <div className="flex flex-col w-full">
        <div className="flex justify-between">
            <div className="label2 lg:label1" data-testid="header">{t(ACCOUNT_SETTINGS.ADD_PASSKEY)}</div>
            <div
          onClick={() => {
            setShowPassKeyPopUp(false);
          }}
          className="cursor-pointer"
          data-testid="close-button"
        >
          {xCloseBtn}
        </div>
        </div>
       
        <div className="body3 lg:body2 py-2 lg:py-4" data-testid="body">
          {t(ACCOUNT_SETTINGS.ADD_PASSKEY_DESC)}
        </div>
        <Form
          onSubmit={() => void(0)}
          mutators={{}}
          render={() => (
            <FormControl
              fieldProps={{
                name: "data.passKey",
                component: "input",
              }}
              checked={isChecked}
              type="checkbox"
              label={t(ACCOUNT_SETTINGS.DONT_SHOW_PASSKEY_POP_UP_AGAIN)}
              onChange={(e) => {
                onCheckboxClick(e.target.checked)
              }}
            />
          )}
        />

        <div className="mt-4 flex justify-end lg:mt-6">
          <Button
            variant="blueOutlined"
            size="lg"
            type="button"
            onClick={() => {
                if(isChecked){
                  setDNDPasskey(true);
                  setShowPassKeyPopUp(false);
                }else{
                  setShowPassKeyPopUp(false);
                }
            }}
            classes="mr-4 bg-white label2 lg:label1"
          >
            {t(ACCOUNT_SETTINGS.DONT_SHOW_PASSKEY_POP_UP_CANCEL)}
          </Button>

          <Button
            variant="primary"
            size="lg"
            type="button"
            onClick={() => {
              navigate(ROUTES_PATH.MANAGE_PASSKEY);
            }}
            classes="lg:px-8 label2 lg:label1"
          >
            {t(ACCOUNT_SETTINGS.ADD_PASSKEY_BUTTON)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PassKeyPopUp;
