
import { useEffect } from "react";
import screensets from "../../config/gigya/screensets";
import {
  AUTHENTICATION_STATUS,
} from "../../constants";
import useRequireAuth from "../../hooks/useRequireAuth";
import gigya from "../../lib/gigya";

export function ManagePassKeyInfo() {
  const auth = useRequireAuth();
 
   useEffect(() => {
    if (auth?.status === AUTHENTICATION_STATUS.AUTHENTICATED) {
      gigya?.accounts.showScreenSet(screensets.MANAGEPASSKEY);
    }
  }, [auth]);
  if (!auth?.user?.UID) return null;

  return <div id={screensets.MANAGEPASSKEY.containerID} />;
}
