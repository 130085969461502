import { useTranslation } from "react-i18next";
import * as Modal from "../../../../components/Modal";
import { LIFESTAGE } from "../../../../constants/lifestage";
import { LifestageMilestone } from "../../../../constants/lifestageMilestone";
import { ChevronRight } from "../../../../icons/ChevronRight";
import { UncompletedMilestoneBadge } from "../../../../icons/UncompletedMilestoneBadge";
import { CompleteMilestoneForm } from "./CompleteMilestoneForm";

export function UncompletedMilestoneItem({
  name,
  estimatedDate,
  description,
  id,
  petId,
}: {
  name: string;
  estimatedDate: string;
  description: string;
  id: string;
  petId: string;
}) {
  const { t } = useTranslation();

  return (
    <Modal.Root>
      <Modal.Trigger asChild>
        <button className="grid_hphv2 w-full grid-cols-[44px_auto] gap-2 rounded-lg border border-brand-color-library-gray-100 p-4 text-start shadow-dp2 md:grid-cols-[52px_auto] md:gap-4 md:p-6">
          <UncompletedMilestoneBadge className="md:h-15 h-12 w-11 fill-brand-color-library-gray-300 md:w-14" />
          <div>
            <div className="grid_hphv2 mb-2 grid-cols-[auto_24px]">
              <h3 className="label2 md:label1 truncate text-start text-brand-color-library-gray-900">
                {name}
              </h3>
              <ChevronRight />
            </div>
            <p className="body3 md:body2 leading-[1] text-brand-color-library-gray-500">
              {t(LifestageMilestone.ESTIMATED)} {estimatedDate}
            </p>
          </div>
        </button>
      </Modal.Trigger>
      <Modal.SideModal title={t(LIFESTAGE.COMPLETE_MILESTONE)}>
        <div className="w-full">
          <div className="m-auto max-w-[554px] px-5 pt-6 md:pt-8">
            <Modal.Description asChild>
              <>
                <h3 className="headline4 md:headline3 mb-6">{name}</h3>
                <p className="body3 md:body2 mb-6">{description}</p>
              </>
            </Modal.Description>
            <CompleteMilestoneForm petId={petId} milestoneId={id} />
          </div>
        </div>
      </Modal.SideModal>
    </Modal.Root>
  );
}
