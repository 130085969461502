import {
  IconAdd,
  IconCat,
  IconDog,
  IconHome,
  IconSetting,
  SideMenuItemType,
} from "ui-2";
import { ROUTES_PATH } from "./routes";
import { SIDEBAR } from "./sidebar";
import GTM_ACTIONS from "../config/gtmActions";
import { datalayerPush } from "../lib/gtm";

export const MENU_PATH = {
  ADD_PET: ROUTES_PATH.ADD_PET,
  ACCOUNT_HOME: ROUTES_PATH.ACCOUNT_HOME,
  MY_PETS: ROUTES_PATH.MY_PETS,
  ACCOUNT_SETTINGS: ROUTES_PATH.ACCOUNT_SETTINGS,
  COMMUNICATION_PREFERENCES: ROUTES_PATH.COMMUNICATION_PREFERENCES,
  CHANGE_PASSWORD: ROUTES_PATH.CHANGE_PASSWORD,
  MANAGE_PASSKEY: ROUTES_PATH.MANAGE_PASSKEY,
};

export const SIDEMENU_ITEMS: Array<SideMenuItemType> = [
  {
    title: SIDEBAR.ACCOUNT_HOME,
    path: MENU_PATH.ACCOUNT_HOME,
    icon: (
      <div className="flex h-6 w-6 items-center justify-center">{IconHome}</div>
    ),
    classes: "border-b-[1px]",
  },
  {
    title: SIDEBAR.MY_PETS.HEADING,
    path: MENU_PATH.MY_PETS,
    icon: (
      <span className="flex flex-row">
        <div className="flex h-6 w-6 items-center justify-center">
          {IconDog}
        </div>
        <div className="flex h-6 w-6 items-center justify-center">
          {IconCat}
        </div>
      </span>
    ),
    classes: "",
    onClick: () => {
    datalayerPush(GTM_ACTIONS.LEFT_SIDE_NAV_MY_PETS)
    },  
    subcategory: [
      {
        title: SIDEBAR.MY_PETS.SUBCATEGORY,
        path: MENU_PATH.ADD_PET,
        icon: (
          <div className="flex h-6 w-6 items-center justify-center">
            {IconAdd}
          </div>
        ),
        classes: "",
        onClick: () => datalayerPush(GTM_ACTIONS.LEFT_SIDE_NAV_ADD_A_PET),
      },
    ],
  },
  {
    title: SIDEBAR.ACCOUNT_SETTINGS.HEADING,
    path: MENU_PATH.ACCOUNT_SETTINGS,
    icon: (
      <div className="flex h-6 w-6 items-center justify-center">
        {IconSetting}
      </div>
    ),
    classes: "",
    onClick: () => {
      datalayerPush(GTM_ACTIONS.ACCOUNT_SETTINGS)
      },  
    subcategory: [
      {
        title: SIDEBAR.ACCOUNT_SETTINGS.SUBCATEGORY1,
        path: MENU_PATH.COMMUNICATION_PREFERENCES,
        classes: "",
      },
      {
        title: SIDEBAR.ACCOUNT_SETTINGS.SUBCATEGORY2,
        path: MENU_PATH.CHANGE_PASSWORD,
        classes: "",
      },
      {
        title: SIDEBAR.ACCOUNT_SETTINGS.SUBCATEGORY3,
        path: MENU_PATH.MANAGE_PASSKEY,
        classes: "",
      },
    ],
  }
];
