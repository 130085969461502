import { useTranslation } from "react-i18next";
import { IconCat, IconDog } from "ui-2";
import { PetType } from "../../../types";
import { ACCOUNT_HOME } from "../../constants/accountHome";
import { AddIcon, Alert, ChevronRight } from "../../icons";
import { isTheSamePet } from "@lib/common";

interface MyPetsObjType {
  data: Array<PetType>;
  rememberedPets?: Array<PetType>;
  onPetClick: (petId: string) => void;
  onAddPetClick: () => void;
  onPrimaryRouteClick: () => void;
  label: string;
  description: string;
  icon?: JSX.Element;
  isLoading?: boolean;
}

export const MyPets = (props: MyPetsObjType) => {
  const { t } = useTranslation();
  const {
    data,
    rememberedPets,
    onPetClick,
    onAddPetClick,
    onPrimaryRouteClick,
    label,
    description,
    icon,
    isLoading,
  } = props;
  const maxNumberofPets = 4;
  const numberOfpets = data.length;
  const justifyContent = numberOfpets <= 2 ? "justify-start" : "justify-evenly";
  const addPet = t(ACCOUNT_HOME.MypetComponentAddPet);

  const defaultImage = (
    petTypeId: number,
    isRememberedPet: boolean | undefined
  ) => {
    return (
      <button className="rounded-full focus:border-blue-500 ">
        <div className="relative p-2">
          {isRememberedPet && (
            <Alert className="absolute top-1 right-1  h-6 w-6" />
          )}
          <div className="rounded-full bg-[#E6E6E6] p-4">
            {petTypeId === 1 ? IconDog : IconCat}
          </div>
        </div>
      </button>
    );
  };
  return (
    <div className="m-auto overflow-hidden rounded-lg bg-white shadow-dp2">
      <div className="flex flex-col">
        <div
          className="bg-white sm:bg-gray-100"
          onClick={() => {
            onPrimaryRouteClick();
          }}
        >
          <div className="m-6 flex items-center justify-between">
            <div>
              <div className="label1 mb-2 flex gap-2">
                {icon}
                <span data-testid="header">{label}</span>
                {(rememberedPets?.length || 0) > 0 && (
                  <Alert className="h-6 w-6" />
                )}
              </div>
              <div className="body2" data-testid="description">
                {description}
              </div>
            </div>
            <div data-testid="rightArrow">
              <ChevronRight className="fill-brand-color-library-blue-500" />
            </div>
          </div>
        </div>
        <div className="hidden bg-white sm:block">
          <div className="m-6">
            {isLoading ? (
              <div className="flex justify-evenly">
                {Array.from({ length: 5 }).map((_, index) => (
                  <div key={index}>
                    <div className="bg-loading-animation mx-auto h-14 w-14" />
                    <div className="bg-loading-animation mt-1 h-3 w-20" />
                  </div>
                ))}
              </div>
            ) : (
              <div className={"flex " + justifyContent}>
                {data.slice(0, maxNumberofPets).map((pet: PetType) => {
                  const isRememberedPet = rememberedPets?.some(
                    (rememberedPet: PetType) => isTheSamePet(pet, rememberedPet)
                  );
                  return (
                    <div
                      className="m-2 flex w-[84px] flex-col items-center justify-between"
                      onClick={() => onPetClick(pet.petId)}
                      key={pet.petId}
                    >
                      <div className="relative flex grow items-center justify-center">
                        {pet.petImage ? (
                          <button className="relative rounded-full focus:border-blue-500">
                            <div className="relative p-2">
                              {isRememberedPet && (
                                <Alert className="absolute top-0 right-0  h-6 w-6" />
                              )}
                              <img
                                src={pet.petImage}
                                alt={pet.petName}
                                className="h-14 w-14 rounded-full object-cover focus:border-4 focus:border-brand-primary"
                              />
                            </div>
                          </button>
                        ) : (
                          defaultImage(pet.petTypeId, isRememberedPet)
                        )}
                      </div>
                      <div className="w-[84px] truncate text-center">
                        <span className="label2">{pet.petName}</span>
                      </div>
                    </div>
                  );
                })}
                <button
                  className="m-2 flex w-[84px] flex-col items-center justify-between"
                  onClick={() => onAddPetClick()}
                >
                  <div className="flex grow items-center justify-center">
                    <div className="rounded-full border border-[#C6C7CC] p-4">
                      <div className="flex h-[19px] w-[19px] items-center justify-center fill-brand-primary ">
                        {<AddIcon />}
                      </div>
                    </div>
                  </div>
                  <div>
                    <span className="label2 text-center text-[#0054A4]">
                      {addPet}
                    </span>
                  </div>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

MyPets.displayName = "MyPets";
