export const SIDEBAR = {
    ACCOUNT_HOME: "accountHome.heading",
    MY_PETS:{
        HEADING:"myPets.heading",
        SUBCATEGORY:"myPets.addPet",
        REMEMBERED_PETS:"myPets.rememberedPets.rememberedPetsHeading"
    },
    ACCOUNT_SETTINGS:{
        HEADING:"accountSettings.heading",
        SUBCATEGORY1:"accountSettings.communicationPreferences",
        SUBCATEGORY2:"accountSettings.changePassword",
        SUBCATEGORY3:"accountSettings.passkey"
    }
}